<template>
  <main class="container">
    <div class="header h3 mt-2">
          Regiones
        <button @click="showNewModal=true" class="btn-2 float-end"><i class="fas fa-plus me-2"/> Agregar Nueva</button>
    </div>


    <!-- ADD NEW MODAL -->
    <b-modal id="modal-1" title="Agregar Región" class="p-3" centered v-model="showNewModal" hide-footer>
        <form @submit.prevent="postData()" class="mx-3">
            <label class="text-green-2">Nombre:</label>
            <input type="text" class="form-control border-secondary mb-4" required v-model="newItem.name">
            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showNewModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-4">
        <div class="col-md-3" v-for="item in items" :key="item.id" >
            <div class="gradient m-3 px-3 py-4 text-center d-flex zoom shadow" 
                 style="cursor:pointer" @click="$router.push('regions/1')">
                <i class="fas fs-1 fa-map-marker-alt me-4 ms-2"></i>
                <h5 v-html="item.name" class="text-start"></h5>
            </div>
        </div>
    </div>
  </main>
</template>

<script>
export default {
data(){
    return {
        items:[],
        showNewModal:false,
        newItem:{},
        table: 'regions',
        token: ''
    }
},
async mounted(){
    await this.getData()
},
methods:{
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getAll'});
            //console.log(res)
            if (res.length >0){
                this.items = res
            }
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
    },
    async postData(){
        try {
            let res = await this.$store.dispatch('post', {path: this.table + '/new', data: this.newItem});
            //console.log(res)
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                //this.items = res
                this.showNewModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
        },
    }
    
}
</script>

<style>

</style>